function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

function checkQuerystring () {
  let href = window.location.href;
  const headerWrap = document.querySelector('#header-wrap');

  if (getParameterByName('nospep', href) === 'true') {
    headerWrap.insertAdjacentHTML(
      'beforebegin', 
      `<div class="notice-wrap">
        <p>Sorry, we no longer offer Salesperson Exam Prep courses for the state you selected.</p><span class="closeBtn" onclick="this.parentNode.remove(); return false;">×</span>
      </div>`
    )
  } else if (getParameterByName('noep', href) === 'true') {
    headerWrap.insertAdjacentHTML(
      'beforebegin', 
      `<div class="notice-wrap">
        <p>Sorry, we no longer offer Exam Prep for the state you selected.</p><span class="closeBtn" onclick="this.parentNode.remove(); return false;">×</span>
      </div>`
    )
  } else if (getParameterByName('noce', href) === 'true') {
    headerWrap.insertAdjacentHTML(
      'beforebegin', 
      `<div class="notice-wrap">
        <p>Sorry, we no longer offer construction CE courses for the state you selected.</p><span class="closeBtn" onclick="this.parentNode.remove(); return false;">×</span>
      </div>`
    )
  } else {
    return
  }
}

checkQuerystring();